import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import _ from 'lodash';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form } from 'formik-antd';
import { UnAuthRedirect } from '@uz/unitz-components-web/AppRedirect';
import FormCreateAccount from '@uz/unitz-tool-components/FormCreateAccount';
import Button from '@uz/unitz-components-web/Button';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const Index = () => {
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4">
          <div className="mb-3 text-main uppercase font-semibold text-base">
            {ctx.apply('i18n.t', 'ZoomToolAccount.create_organization')}
          </div>
          <FormProvider form={ctx.get('form')}>
            <Form {...layout} className="account-add-form">
              <div className="mb-4 p-6 bg-white500 rounded-lg shadow-md">
                <FormCreateAccount />
              </div>
              <div className="p-6 bg-white500 rounded-lg shadow-md">
                <Row justify="end">
                  <Col>
                    <Button onClick={ctx.get('form.handleSubmit')} type="primary" size="medium">
                      <span className="capitalize">
                        {_.lowerCase(ctx.apply('i18n.t', 'ZoomToolAccount.Action.create_organization'))}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </FormProvider>
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default Index;
